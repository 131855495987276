import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import burgerBot from "../images/burgerBot.png"
import Burger from '../components/Burger';

const Layout = styled.div`
  display: grid;
  min-height: 100vh;
  @media only screen and (min-width: 992px) {
    height: 100vh;
    grid-template-columns: 1fr 1fr;
  }
`;

const Image = styled.article`
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.article`
  color: white;
  background: black;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;

  display: grid;
  grid-template-row: auto auto auto;
  grid-row-gap: 2rem;
  justify-content: space-between;
`;

const SiteHeading = styled.h1`
  font-family: 'Fredoka One', cursive;
  color: hotpink;
  font-size: 2rem;
  text-align: center;
  margin: 0.5rem;
  align-self: self-start;
  a {
    text-decoration: none;
    color: hotpink;
  }
  a:hover {
    color: yellow;
  }
`

const Heading = styled.h1`
  font-family: 'Fredoka One', cursive;
  color: hotpink;
  font-size: 3.5rem;
  text-align: center;
  margin: 0.5rem;
`
const SubHeading = styled.h2`
  font-family: 'Fredoka One', cursive;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
`

const FutherFillings = styled.p`
  font-family: 'Fredoka One', cursive;
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: 0;
`

const BurgerBotContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  align-self: self-end;

  img {
    grid-area: img;
    justify-self: center;
  }

  grid-template: 'img' 'title' 'button';

  @media only screen and (min-width: 992px) {
    grid-template-columns: auto auto;
    grid-template: 'img title' 'img button';
  }
`;

const BurgerBotTitle = styled.div`
  font-family: 'Fredoka One', cursive;
  color: white;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const BurgerBotSpeech = styled.div`
  font-family: 'Fredoka One', cursive;
  background: yellow;
  color: black;
  font-size: 2.5rem;
  font-family: 'Press Start 2P', cursive;
  padding: 1rem
`;

const BurgerBot = styled.img`
  align-self: self-end;
  max-width: 200px
`;

const Hr = styled.hr`
  border-color: yellow;
  border-thickness: 0.5rem
`;

const IngredientsImage = styled.img`
  margin-top: -20px;
  max-width: 400px;
`;

const ingredientsStyleLookup = {
  'american-cheese': {
    'margin-bottom': '-14px'
  },
  'tomato': {
    'margin-bottom': '10px'
  },
  'gherkin': {
    'margin-bottom': '10px'
  },
  horseradish: {
    'margin-bottom': '10px'
  },
  bacon: {
    'margin-bottom': '10px',
    'margin-top': '-14px'
  },
}
function getIngredientsStyle(slug) {
  if (ingredientsStyleLookup[slug]) {
    return ingredientsStyleLookup[slug]
  }
  return {};
}



export default function Template({
    data, location, ...props // this prop will be injected by the GraphQL query below.
}) {
  const { construction, filling, spices, extras, sauces, salads } = data;
  const [activeExtras, setActiveExtras] = useState([]);
  const [activeSauces, setActiveSauces] = useState([]);
  const [activeSalads, setActiveSalads] = useState([]);


  useEffect(() => {
    const search = new URLSearchParams(location.search);

    if (search.has('extras')) {
      const newExtraSlugs = search.get('extras').split('|');
      const newActiveExtra = extras.edges.filter(({node}) => newExtraSlugs.includes(node.frontmatter.slug)).map(({ node }) => node);
      setActiveExtras(newActiveExtra);
    } else {
      setActiveExtras([]);
    }

    if (search.has('sauces')) {
      const newSauceSlugs = search.get('sauces').split('|');
      const newAtiveSauces = sauces.edges.filter(({node}) => newSauceSlugs.includes(node.frontmatter.slug)).map(({ node }) => node);
      setActiveSauces(newAtiveSauces);
    } else {
      setActiveSauces([]);
    }

    if (search.has('salads')) {
      const newSaladsSlugs = search.get('salads').split('|');
      const newAtiveSalads = salads.edges.filter(({node}) => newSaladsSlugs.includes(node.frontmatter.slug)).map(({ node }) => node);
      setActiveSalads(newAtiveSalads);
    } else {
      setActiveSalads([]);
    }
  }, [location.search])
  
  return (
    <Layout>
      <Image>
        <Burger 
          construction={construction}
          filling={filling}
          spices={spices}
          extras={activeExtras}
          sauces={activeSauces}
          salads={activeSalads}
        />
      </Image>
      <Text>
        <SiteHeading><a href="/">BurgerBot.net</a></SiteHeading>
        <header>
          <Hr />
          <Heading>{spices.frontmatter.title} {filling.frontmatter.title} on a {construction.frontmatter.title}</Heading>
          {activeExtras && activeExtras.length > 0 && (
            <SubHeading>With {activeExtras.map((activeExtra) => {return activeExtra.frontmatter.title}).join(', ')}</SubHeading>
          )}
          {activeSalads && activeSalads.length > 0 && (
            <FutherFillings>And {activeSalads.map((activeSalads) => {return activeSalads.frontmatter.title}).join(', ')}</FutherFillings>
          )}
          {activeSauces && activeSauces.length > 0 && (
            <FutherFillings>Topped with {activeSauces.map((activeSauces) => {return activeSauces.frontmatter.title}).join(', ')}</FutherFillings>
          )}


          <Hr />
        </header>
        <BurgerBotContainer>
          <BurgerBotTitle>What do you think BurgerBot?</BurgerBotTitle>
          <BurgerBot src={burgerBot} alt="Burger Bot" />
          <BurgerBotSpeech>I'd eat that</BurgerBotSpeech>
        </BurgerBotContainer>
      </Text>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($constructionSlug: String!, $fillingSlug: String!, $spicesSlug: String!) {
    construction: markdownRemark(frontmatter: { slug: { eq: $constructionSlug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    filling: markdownRemark(frontmatter: { slug: { eq: $fillingSlug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    spices: markdownRemark(frontmatter: { slug: { eq: $spicesSlug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    extras: allMarkdownRemark(filter: {frontmatter: {type: {eq: "extras"}}}) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
          }
        }
      }
    }
    sauces: allMarkdownRemark(filter: {frontmatter: {type: {eq: "sauces"}}}) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
          }
        }
      }
    }
    salads: allMarkdownRemark(filter: {frontmatter: {type: {eq: "salad"}}}) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
          }
        }
      }
    }
  }
`
